import { faClose, faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Col, Modal } from "react-bootstrap";
import QrScanner from "react-qr-scanner";

const CardScanner = (props) => {
  const [cameraPos, gantiCemara] = useState("environment");

  const handleError = (err) => {
    console.error(err);
  };

  const handleScan = (data) => {
    if (data) {
      props.successCard(data.text);
    }
  };

  return (
    <Modal {...props} centered={true} className="text-center">
      <Col className="text-end p-3">
        <FontAwesomeIcon icon={faClose} color="red" onClick={props.onHide} />
      </Col>
      <Modal.Body>
        <h4 className="tx-red-bold-small">Scan Kartumu!</h4>
        <QrScanner
          style={{ width: "100%" }}
          delay={300}
          onError={handleError}
          onScan={handleScan}
          className="qr-scanner"
          constraints={{
            video: {
              facingMode: cameraPos,
            },
          }}
        />
        <Button
          className="w-100 mb-2 mt-4 btn-activation"
          onClick={() =>
            gantiCemara(cameraPos === "environment" ? "user" : "environment")
          }
        >
          <FontAwesomeIcon style={{ marginRight: "10px" }} icon={faRotate} />
          Ganti Posisi Kamera
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default CardScanner;
