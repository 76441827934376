import { faClose, faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { formatCardInput } from "../../helper";

const ActionCard = (props) => {
  const [cardValue, setCardValue] = useState("");

  return (
    <Modal {...props} centered={true} className="text-center">
      <Col className="text-end p-3">
        <FontAwesomeIcon icon={faClose} color="red" onClick={props.onHide} />
      </Col>
      <Modal.Body>
        <h4 className="tx-red-bold-small">Periksa Kartumu</h4>
        <InputGroup className="mb-2 input-search-card">
          <Form.Control
            placeholder="Masukkan Nomor Kartu"
            aria-label="search-product"
            id="card_number"
            value={cardValue}
            onChange={(e) => setCardValue(formatCardInput(e.target.value))}
            style={{
              padding: "10px",
              border: "0px solid black",
              backgroundColor: "transparent",
            }}
          />
          <InputGroup.Text
            onClick={() => [
              props?.successCard(`A1_${cardValue}`),
              setCardValue(""),
            ]}
            style={{
              border: "0px solid black",
              backgroundColor: "red",
              color: "white",
              cursor: "pointer",
              fontWeight: 600,
            }}
          >
            Periksa
          </InputGroup.Text>
        </InputGroup>
        <Col className="d-flex justify-content-between align-items-center text-center mb-2">
          <hr style={{ backgroundColor: "rgb(207, 207, 207)", width: "42%" }} />
          <h6
            style={{
              marginBottom: "-1px",
              color: "rgb(100, 100, 100)",
              fontWeight: 200,
            }}
          >
            atau
          </h6>
          <hr style={{ backgroundColor: "rgb(207, 207, 207)", width: "42%" }} />
        </Col>
        <Button className="btn-activation w-100" onClick={props?.openScanner}>
        <FontAwesomeIcon icon={faCamera} style={{ marginRight: "10px" }} />
          Scan Kartu
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default ActionCard;
