import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Modal, Table } from "react-bootstrap";
import { dateformat, 
  // formatNumberWithout
 } from "../../helper";

const CardDetail = (props) => {
  return (
    <Modal {...props} centered={true} className="text-center">
      <Col className="text-end p-3">
        <FontAwesomeIcon icon={faClose} color="red" onClick={props.onHide} />
      </Col>
      <Modal.Body>
        {/* <h4 className="tx-red-bold-small">Saldo Kamu!</h4> */}
        <Col className="col-card text-start mb-4">
          <Col>
            <h4 className="tx-card-number">Nomor Kartu</h4>
            <h4 className="tx-card-number">
              {props?.datas?.card_number.replace("A1_", "")}
            </h4>
          </Col>
          <Col className="mt-auto">
            <h4 className="tx-card-balance">Pemilik Kartu</h4>
            <h4 className="tx-card-balance-number">{props?.datas?.name}</h4>
          </Col>
        </Col>
        <h4 className="tx-red-bold-small">Detail Transaksi</h4>
        <Table responsive className="text-nowrap table-fixed text-start">
          <thead className="table-light">
            <tr>
              <th style={{ backgroundColor: "#BCDCFA" }} scope="col">
                No
              </th>
              <th style={{ backgroundColor: "#BCDCFA" }} scope="col">
                Waktu Transaksi
              </th>
              {/* <th style={{ backgroundColor: "#BCDCFA" }} scope="col">
                Total
              </th> */}
            </tr>
          </thead>
          {props?.datas?.transaction_histories
            ? props?.datas?.transaction_histories.map((item, index) => {
                return (
                  <tbody key={index}>
                    <tr>
                      <td>{index + 1}</td>
                      <td>{dateformat(item.created_at)}</td>
                      {/* <td>{formatNumberWithout(item.amount)}</td> */}
                    </tr>
                  </tbody>
                );
              })
            : ""}
        </Table>
        <Button
          className="w-100 mb-2 mt-4 btn-activation"
          onClick={props.onHide}
        >
          Tutup
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default CardDetail;
