import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Nav,
  Row,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faArrowCircleRight,
  faCheck,
  faWallet,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { formatNumberWithout } from "../helper";
import axios from "axios";
import ProductPopUp from "../components/modal/ProductPopUp";
import ActionCard from "../components/modal/ActionCard";
import CardDetail from "../components/modal/CardDetail";
import CardScanner from "../components/modal/CardScanner";
import InputDataCustomer from "../components/modal/InputDataCustomer";

function Home() {
  const [modalProduct, setModalProduct] = useState(false);
  const [modalCardDetail, setModalCardDetail] = useState(false);
  const [modalInputData, setModalInputData] = useState(false);
  const [modalCardAction, setModalCardAction] = useState(false);
  const [modalScanner, setModalScanner] = useState(false);
  const [productStatus, setProductStatus] = useState(1);
  const [listProduct, setListProduct] = useState();
  // const [listMerchant, setListMerchant] = useState([]);
  const [paginPage, setPaginPage] = useState(1);
  const [productSearch, setProductSearch] = useState("");
  // const [merchantSearch, setMerchantSearch] = useState("");
  const [cardDetail, setCardDetail] = useState();

  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const getProduct = useCallback(async () => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_API_URL}/api/product/lp?page=${paginPage}&search=${productSearch}`,
        {
          headers: {
            "Content-Type": "application/json",
            token: process.env.REACT_APP_API_KEY,
            "API-TOKEN": localStorage.getItem("token"),
          },
          method: "get",
          data: {},
        }
      );
      if (response.data.status) {
        setListProduct(response.data?.data);
      } else {
      }
    } catch (error) {}
  }, [paginPage, productSearch]);

  const generateToken = useCallback(async () => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_API_URL}/api/auth/generate-token`,
        {
          headers: {
            "Content-Type": "application/json",
            token: process.env.REACT_APP_API_KEY,
          },
          method: "post",
          data: {},
        }
      );
      if (response.data.status) {
        localStorage.setItem("token", response.data.data.token);
        localStorage.setItem("expire", response.data.data.expire_at);
        getProduct();
      } else {
        throw new Error("Request failed");
      }
    } catch (error) {}
  }, [getProduct]);

  const getDetailCard = useCallback(async (number) => {
    try {
      setModalScanner(false);
      setModalCardAction(false);
      const params = {
        card_number: number,
      };
      const response = await axios(
        `${process.env.REACT_APP_API_URL}/api/card/lp/detail-card`,
        {
          headers: {
            "Content-Type": "application/json",
            "API-TOKEN": localStorage.getItem("token"),
          },
          method: "post",
          data: params,
        }
      );
      if (response.data.status) {
        setCardDetail(response.data.data);
        setTimeout(() => {
          if (
            response.data.data.amount === null ||
            response.data.data.amount === true
          ) {
            setModalInputData(true);
          } else {
            setModalCardDetail(true);
          }
        }, 500);
      } else {
        alert("Kartu Tidak Ditemukan");
      }
    } catch (error) {}
  }, []);

  const handleActivationCard = async (param) => {
    try {
      const params = {
        no_kk: param.no_kk,
        nik: param.nik,
        name: param.name,
        phone: param.phone,
        rt_rw: `${param.no_rt}/${param.no_rw}`,
        district_id: param.district,
        sub_district_id: param.sub_district,
        card_number: cardDetail?.card_number,
        total_family_member: param.total_member,
      };

      const response = await axios(
        `${process.env.REACT_APP_API_URL}/api/card/lp/activation`,
        {
          headers: {
            "Content-Type": "application/json",
            "API-TOKEN": localStorage.getItem("token"),
          },
          method: "post",
          data: params,
        }
      );
      if (response.data.status) {
        alert("Berhasil Aktivasi Kartu, Kartu Sudah Bisa Dipakai");
        setModalInputData(false);
        getDetailCard(cardDetail?.card_number);
      } else {
        if (
          response.data.errors.no_kk[0] === "The no kk has already been taken."
        ) {
          alert("Aktivasi Gagal, Nomor KK Sudah Digunakan");
        } else if (
          response.data.errors.nik[0] === "The nik has already been taken."
        ) {
          alert("Aktivasi Gagal, Nomor NIK Sudah Digunakan");
        } else {
          alert("Aktivasi Gagal, Periksa Kembali Data Yang Dimasukkan");
        }

        throw new Error("Request failed");
      }
    } catch (error) {}
  };

  useEffect(() => {
    setTimeout(() => {
      generateToken();
    }, 2000);
  }, [generateToken]);

  return (
    <div className="home">
      {/* {isMobile ? ( */}
      <Container className="text-center p-0">
        <ProductPopUp
          show={modalProduct}
          onHide={() => setModalProduct(false)}
          type={productStatus}
        />

        <ActionCard
          show={modalCardAction}
          onHide={() => setModalCardAction(false)}
          openScanner={() => [setModalCardAction(false), setModalScanner(true)]}
          successCard={getDetailCard}
        />

        <CardDetail
          show={modalCardDetail}
          onHide={() => [setModalCardDetail(false), window.location.reload()]}
          datas={cardDetail}
        />

        <CardScanner
          show={modalScanner}
          onHide={() => setModalScanner(false)}
          successCard={getDetailCard}
        />

        <InputDataCustomer
          handleActivation={handleActivationCard}
          show={modalInputData}
          onHide={() => setModalInputData(false)}
        />

        {/* <div
          className="btn-goto-wa"
          onClick={() =>
            getLink(
              `https://api.whatsapp.com/send/?phone=+6282336441318&text=Halo+Admin&type=phone_number&app_absent=0`
            )
          }
        /> */}
        <Nav
          fill={true}
          className="text-white mb-3 mt-3 d-flex justify-content-around align-items-center"
        >
          <Nav.Link href="#home" className="text-white">
            Beranda
          </Nav.Link>
          <Nav.Link
            className="text-white"
            onClick={() => scrollToSection(section1Ref)}
          >
            Produk
          </Nav.Link>
          <Image
            src={require("../images/icon.png")}
            // style={{ width: "15%", height: "15%" }}
            className="mx-2"
            style={{ width: "auto", maxWidth: "50px", height: "auto" }}
          />
          <Nav.Link
            className="text-white"
            onClick={() => scrollToSection(section2Ref)}
          >
            Kartu
          </Nav.Link>
          <Nav.Link
            className="text-white"
            onClick={() => scrollToSection(section3Ref)}
          >
            Toko
          </Nav.Link>
        </Nav>
        <Col className="mb-4 px-3">
          <Row
            className="justify-content-between"
            style={{ marginBottom: "-50px" }}
          >
            <Image
              src={require("../images/illustration/illustration-04.png")}
              className="icon-ill-01"
            />
            <Image
              src={require("../images/illustration/illustration-03.png")}
              className="icon-ill-02"
            />
          </Row>
          <Image src={require("../images/header-hero.png")} className="w-100" />
          <Row
            className="justify-content-between"
            style={{ marginTop: "-50px" }}
          >
            <Image
              src={require("../images/illustration/illustration-01.png")}
              className="icon-ill-03"
            />
            <Image
              src={require("../images/illustration/illustration-02.png")}
              className="icon-ill-04"
            />
          </Row>
        </Col>
        <Image
          src={require("../images/text-section-2.png")}
          className="w-75 mb-5"
        />
        <Image
          src={require("../images/line-section.png")}
          className="w-75 mb-5"
        />
        <h3 className="tx-red-bold" ref={section2Ref}>
          Kartu Keren Untukmu!{" "}
        </h3>
        <Image
          src={require("../images/card-section-3.png")}
          className="w-100 mb-5 p-0"
        />
        <Col className="px-4">
          <h4 className="tx-desc-kartu-keren mb-4">
            {/* Kartu Keren adalah kartu khusus bagi warga Bekasi, memberikan akses
            pangan murah untuk kebutuhan pokok sehari-hari. Dapatkan kemudahan
            membeli sembako dengan harga terjangkau dengan Kartu Keren! */}
            Kartu Keren adalah kartu khusus bagi warga Bekasi,
            memberikan kemudahan dan akses pangan murah untuk kebutuhan pokok sehari-hari dengan metode bazar atau tebus murah.
            <br></br>
            Dapatkan kemudahan membeli sembako dengan harga terjangkau dengan Kartu Keren!
          </h4>
          <h4 style={{ fontWeight: "400" }}>Sudah Punya Kartu Keren?</h4>
        </Col>
        <Col className="px-4 mb-4 d-flex justify-content-center align-items-center">
          <Button
            className="btn-activation"
            onClick={() => [setModalCardAction(true)]}
          >
            <FontAwesomeIcon icon={faCheck} style={{ marginRight: "10px" }} />
            Aktivasi
          </Button>
          <Button
            className="btn-check-balance"
            onClick={() => [setModalCardAction(true)]}
          >
            <FontAwesomeIcon icon={faWallet} style={{ marginRight: "10px" }} />
            Check Kartu!
          </Button>
        </Col>
        {/* <h4 style={{ fontWeight: "400" }}>Mau Punya Kartu Keren?</h4>
        <Button
          className="btn-get-kartu-keren"
          onClick={() =>
            getLink(
              `https://api.whatsapp.com/send/?phone=+6282336441318&text=Halo+Admin&type=phone_number&app_absent=0`
            )
          }
        >
          <FontAwesomeIcon icon={faPhone} style={{ marginRight: "10px" }} />
          Tanya Melalui Whatsapp, Yuk!
        </Button> */}
        <Col className="px-3">
          <h4 className="tx-red-bold">Sembako Murah</h4>
          <InputGroup className="mb-3 input-search">
            <Form.Control
              onChange={(e) => setProductSearch(e.target.value)}
              placeholder="Cari Barang Disini"
              aria-label="search-product"
              style={{
                border: "0px solid black",
                backgroundColor: "transparent",
              }}
            />
            <InputGroup.Text
              onClick={() => getProduct()}
              style={{
                border: "0px solid black",
                backgroundColor: "transparent",
              }}
            >
              <FontAwesomeIcon icon={faSearch} color="gray" />
            </InputGroup.Text>
          </InputGroup>
        </Col>
        <Col
          className="justify-content-between d-flex mb-2 px-3 text-start flex-wrap"
          ref={section1Ref}
        >
          {listProduct?.data?.map((i, index) => {
            return (
              <Col xs={6} style={{ padding: "10px" }} key={index}>
                <Col className="card-product">
                  <Image
                    src={`${process.env.REACT_APP_API_URL}/${i.image}`}
                    className={`${i.status === 0 && "img-product"} w-100 mb-3`}
                  />
                  <h4 className="tx-product-name">{i.name}</h4>
                  <h4 className="tx-product-desc">{i.description}</h4>
                  <Row className="justify-content-between align-items-center">
                    <Col className="text-start">
                      <h4 className="tx-product-cross-price">
                        Rp {formatNumberWithout(i.price)}
                      </h4>
                      <h4 className="tx-product-price">
                        Rp {formatNumberWithout(i.price - i.discount)}
                      </h4>
                    </Col>
                    <Col className="text-end w-100">
                      <Button
                        onClick={() => [
                          setModalProduct(true),
                          setProductStatus(i.status),
                        ]}
                        className="custom-button"
                      >
                        Pilih
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Col>
            );
          })}
        </Col>

        <Col className="px-3 mb-3 d-flex justify-content-between">
          <Col className="text-start px-3" xs={6}>
            <h4 className="tx-pagination-total">
              Menampilkan <b>{listProduct?.to}</b> dari{" "}
              <b>{listProduct?.total}</b> produk
            </h4>
          </Col>
          <Col className="text-end px-3 d-flex justify-content-between" xs={6}>
            <FontAwesomeIcon
              icon={faArrowCircleLeft}
              color="red"
              onClick={() => paginPage >= 1 && setPaginPage(paginPage - 1)}
            />
            <h4 className="tx-pagination-total">
              Halaman ke <b>{paginPage}</b> dari <b>{listProduct?.last_page}</b>
            </h4>
            <FontAwesomeIcon
              icon={faArrowCircleRight}
              color="red"
              onClick={() =>
                paginPage <= listProduct?.last_page &&
                setPaginPage(paginPage + 1)
              }
            />
          </Col>
        </Col>
        {/* <Col className="px-3 mb-3 mt-5">
          <Image
            src={require("../images/lokasi-bazar-27.png")}
            style={{ width: "100%" }}
            className="mb-3"
          />
          <Button
            className="mb-3"
            onClick={() => getLink(`https://maps.app.goo.gl/JV3wFgHngBaAnrit7`)}
            style={{
              backgroundColor: "red",
              border: "0px",
              padding: "10px",
              paddingInline: "10px",
            }}
          >
            <Image
              src={require("../images/location.png")}
              style={{
                width: "10%",
                marginRight: "10px",
              }}
            />{" "}
            Maps Stand Kartu Keren
          </Button>
        </Col> */}

        {/* <Col className="text-center px-3" ref={section3Ref}>
          <h4 className="tx-red-bold">Lokasi Toko Kami Yang Segera Hadir</h4>
          <InputGroup className="mb-1 input-search">
            <Form.Control
              placeholder="Cari Nama Toko Disini"
              onChange={(e) => setMerchantSearch(e.target.value)}
              aria-label="search-product"
              style={{
                border: "0px solid black",
                backgroundColor: "transparent",
              }}
            />
            <InputGroup.Text
              onClick={() => getMerchant()}
              style={{
                border: "0px solid black",
                backgroundColor: "transparent",
              }}
            >
              <FontAwesomeIcon icon={faSearch} color="gray" />
            </InputGroup.Text>
          </InputGroup>
          <Col className="mb-4 box-merchant">
            {listMerchant?.map((i, index) => {
              return (
                <Row key={index}>
                  <Col className="text-start" xs={9}>
                    <Col>
                      <h4 className="tx-merchant-name">
                        <FontAwesomeIcon
                          icon={faStore}
                          color="red"
                          style={{ marginRight: "5px" }}
                        />
                        {i.name}
                      </h4>
                    </Col>
                    <Col>
                      <h4 className="tx-merchant-address">{i.address}</h4>
                    </Col>
                  </Col>
                  <Col className="text-center" xs={3}>
                    <Image
                      src={require("../images/icon-maps.png")}
                      onClick={() =>
                        window.open(
                          `https://www.google.com/maps?q=${i.latitude},${i.longitude}`,
                          "_blank"
                        )
                      }
                      style={{ width: "80%" }}
                    />
                  </Col>
                  {index !== listMerchant.length - 1 && (
                    <hr style={{ marginInline: "6%", width: "88%" }} />
                  )}
                </Row>
              );
            })}
          </Col>
        </Col> */}
        <Image
          src={require("../images/text-section-4.png")}
          className="w-75 mb-2"
        />
        <Col className="px-3 d-flex justify-content-between flex-wrap">
          <Image
            src={require("../images/testimony-01.png")}
            className="w-100"
          />
        </Col>
      </Container>
      {/* ) : (
        <p>Anda menggunakan perangkat desktop</p>
      )} */}
    </div>
  );
}

export default Home;
