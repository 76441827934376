const formatNumberWithout = (number) => {
  try {
    // Ensure the input is a valid number before proceeding
    if (number === null || number === undefined || isNaN(Number(number))) {
      console.warn("Invalid number passed to formatNumberWithout:", number);
      return "0"; // Return a default value (e.g., "0") for invalid input
    }

    const formattedNumber = Number(number).toLocaleString(); // Ensure number is cast properly
    const [integerPart, decimalPart] = formattedNumber.split(".");
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const formattedCurrency = decimalPart
      ? `${formattedInteger}.${decimalPart}`
      : formattedInteger;
    return formattedCurrency;
  } catch (e) {
    console.error("Error in formatNumberWithout:", e);
    return "0"; // Return a fallback value if there's an error
  }
};

function formatCurrencyInput(input) {
  if (!input) return "0"; // Return fallback if input is undefined or empty
  const numericInput = input.replace(/[^0-9.]/g, "");
  const [integerPart, decimalPart] = numericInput.split(".");
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const formattedCurrency = decimalPart
    ? `${formattedInteger}.${decimalPart}`
    : formattedInteger;
  return formattedCurrency;
}

const formatNumber = (price) => {
  try {
    const priceNumber = Number(price);

    if (!isNaN(priceNumber)) {
      const cur = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      return cur.format(priceNumber);
    } else {
      return ""; // Return empty string if it's not a valid number
    }
  } catch (e) {
    console.error(e);
    return ""; // Return empty string in case of an error
  }
};

const getLink = (link) => {
  return window.open(link, "_blank", "rel=noopener noreferrer");
};

const formatCardInput = (value = "0") => {
  if (!value) return ""; // Handle empty or undefined values
  let sanitizedValue = value.replace(/\D/g, "");

  if (sanitizedValue.length > 16) {
    sanitizedValue = sanitizedValue.substring(0, 16);
  }

  const formattedValue = sanitizedValue.match(/.{1,4}/g)?.join("-") || "";

  return formattedValue;
};

const dateformat = (tanggalISO) => {
  try {
    if (!tanggalISO) throw new Error("Invalid date"); // Handle invalid date
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      day: "numeric",
      month: "long",
      year: "numeric",
    };

    const tanggal = new Date(tanggalISO);
    if (isNaN(tanggal)) throw new Error("Invalid date format"); // Check if date is valid

    return tanggal.toLocaleString("id-ID", options);
  } catch (e) {
    console.error(e);
    return ""; // Return empty string if there's an error
  }
};

const validateInput = (value) => {
  if (!value) {
    return value;
  }
  return value.replace(/[^0-9]/g, "");
};

const cleanInput = (inputString) => {
  // Hapus semua karakter selain angka
  let filteredValue = inputString.replace(/[^\d]/g, "");

  // Pastikan panjangnya tidak lebih dari 14 karakter
  filteredValue = filteredValue.substring(0, 14);

  // Ganti dua angka pertama dengan "08"
  if (filteredValue.length > 1) {
    filteredValue = "08" + filteredValue.slice(2);
  }

  return filteredValue;
};

const validateInputName = (value) => {
  if (!value) {
    return value;
  }
  return value.replace(/[^a-zA-Z\s]/g, "");
};

export {
  formatNumberWithout,
  validateInputName,
  cleanInput,
  validateInput,
  formatCurrencyInput,
  formatNumber,
  getLink,
  formatCardInput,
  dateformat,
};
