import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Image, Modal } from "react-bootstrap";

const ProductPopUp = (props) => {
  return (
    <Modal {...props} centered={true} className="text-center">
      <Col className="text-end p-3">
        <FontAwesomeIcon icon={faClose} color="red" onClick={props.onHide} />
      </Col>
      <Modal.Body>
        <Image
          src={require("../../images/caleg-picture.png")}
          style={{ width: "80%" }}
          className="mb-4"
        />
        <Image
          src={
            props.type === 0
              ? require("../../images/success-select.png")
              : require("../../images/claim-near.png")
          }
          style={{ width: "80%" }}
          className="mb-5"
        />
        <Button className="btn-activation w-100" onClick={props.onHide}>
          Pasti Pilih No.3
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default ProductPopUp;
