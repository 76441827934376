import { faClose, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { optionFamilyMember } from "../../utils";
import FormSelect from "../../widget/form-select/FormSelect";
import { useState, useEffect } from "react";
import axios from "axios";
import { validateInput, validateInputName, cleanInput } from "../../helper";
import Select from "react-select";

const InputDataCustomer = (props) => {
  const [noKartuKeluarga, setNoKartuKeluarga] = useState();
  const [noNik, setNoNik] = useState();
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [districtOptions, setDistrictOptions] = useState([]);
  const [subDistrictOptions, setSubDistrictOptions] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedSubDistrict, setSelectedSubDistrict] = useState(null);
  const [districtSearchTerm, setDistrictSearchTerm] = useState("");
  const [subDistrictSearchTerm, setSubDistrictSearchTerm] = useState("");

  const filterOption = (option, inputValue) => {
    const input =
      typeof inputValue === "string" ? inputValue.toLowerCase() : "";
    const label = String(option.label || "").toLowerCase();
    return label.includes(input);
  };

  useEffect(() => {
    const fetchDistrictOptions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/region/district?search=${districtSearchTerm}`,
          {
            headers: {
              "Content-Type": "application/json",
              token: process.env.REACT_APP_API_KEY,
              "API-TOKEN": localStorage.getItem("token"),
            },
          }
        );
        if (response.data.status) {
          const options = response.data.data.map((district) => ({
            value: district.id,
            label: district.name,
          }));
          setDistrictOptions(options);
        }
      } catch (error) {
        console.error("Failed to fetch district options:", error);
      }
    };

    if (districtSearchTerm) {
      const delayDebounceFn = setTimeout(() => {
        fetchDistrictOptions();
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [districtSearchTerm]);

  useEffect(() => {
    const fetchSubDistrictOptions = async () => {
      if (!selectedDistrict) return;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/region/sub-district?search=${subDistrictSearchTerm}&district_id=${selectedDistrict.value}`,
          {
            headers: {
              "Content-Type": "application/json",
              token: process.env.REACT_APP_API_KEY,
              "API-TOKEN": localStorage.getItem("token"),
            },
          }
        );
        if (response.data.status) {
          const options = response.data.data.map((subDistrict) => ({
            value: subDistrict.id,
            label: subDistrict.name,
          }));
          setSubDistrictOptions(options);
        }
      } catch (error) {
        console.error("Failed to fetch sub-district options:", error);
      }
    };

    if (subDistrictSearchTerm) {
      const delayDebounceFn = setTimeout(() => {
        fetchSubDistrictOptions();
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [subDistrictSearchTerm, selectedDistrict]);

  const handleActivation = (e) => {
    e.preventDefault();
    const params = {
      no_kk: noKartuKeluarga,
      nik: noNik,
      name: name,
      phone: phone,
      no_rt: "",
      no_rw: "",
      district: selectedDistrict?.value,
      sub_district: selectedSubDistrict?.value,
      total_member: document.getElementById("total_members").value,
    };
    props.handleActivation(params);
  };

  return (
    <Modal {...props} centered={true} className="text-center">
      <Col className="text-end p-3">
        <FontAwesomeIcon icon={faClose} color="red" onClick={props.onHide} />
      </Col>
      <Modal.Body>
        <h4 className="tx-red-bold-small">Lengkapi Data Untuk Aktivasi!</h4>
        <Col className="text-start mb-4">
          <Form onSubmit={handleActivation}>
            <Form.Label>
              No. Kartu Keluarga<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              onChange={(e) =>
                setNoKartuKeluarga(validateInput(e.target.value))
              }
              maxLength={16}
              value={noKartuKeluarga}
              className="input-search mb-2"
              placeholder="16 Angka"
              required
            />
            <Form.Label>
              No. KTP (NIK)<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              value={noNik}
              maxLength={16}
              onChange={(e) => setNoNik(validateInput(e.target.value))}
              className="input-search mb-2"
              placeholder="16 Angka"
              required
            />
            <Form.Label>
              Nama Lengkap<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              value={name}
              onChange={(e) => setName(validateInputName(e.target.value))}
              className="input-search mb-2"
              placeholder="Nama Sesuai KTP"
              required
            />
            <Form.Label>
              No. HP<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              value={phone}
              onChange={(e) => setPhone(cleanInput(e.target.value))}
              className="input-search mb-2"
              // placeholder="08xxx"
              required
            />
            <Form.Label>
              Kecamatan<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Select
              options={districtOptions}
              value={selectedDistrict}
              onInputChange={(inputValue) => setDistrictSearchTerm(inputValue)}
              onChange={(option) => {
                setSelectedDistrict(option);
                setSelectedSubDistrict(null);
                setSubDistrictOptions([]);
              }}
              placeholder="Cari Kecamatan"
              isClearable
              className="mb-4"
              filterOption={filterOption}
            />
            <Form.Label>
              Kelurahan<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Select
              options={subDistrictOptions}
              value={selectedSubDistrict}
              onInputChange={(inputValue) =>
                setSubDistrictSearchTerm(inputValue)
              }
              onChange={setSelectedSubDistrict}
              placeholder="Cari Kelurahan"
              isClearable
              isDisabled={!selectedDistrict}
              className="mb-4"
              filterOption={filterOption}
            />
            <Form.Label>
              Jumlah Anggota Keluarga<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              options={optionFamilyMember}
              as={FormSelect}
              id="total_members"
              className="input-search mb-4"
              placeholder="Pilih Salah Satu"
              required
            />

            <Col className="d-flex text-start mb-4">
              <Form.Check required style={{ marginRight: "10px" }} />
              <Form.Label>
                Data yang di masukkan adalah benar, dan saya siap mempertanggung
                jawabkannya<span style={{ color: "red" }}>*</span>
              </Form.Label>
            </Col>
            <Button type="submit" className="btn-activation w-100">
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ marginRight: "10px" }}
              />
              Aktivasi Kartu
            </Button>
          </Form>
        </Col>
      </Modal.Body>
    </Modal>
  );
};

export default InputDataCustomer;
