const optionFamilyMember = [
  { value: "1", label: "1 Orang" },
  { value: "2", label: "2 Orang" },
  { value: "3", label: "3 Orang" },
  { value: "3", label: "3 Orang" },
  { value: "4", label: "4 Orang" },
  { value: "5", label: "5 Orang" },
  { value: "6", label: "6 Orang" },
  { value: "7", label: "7 Orang" },
  { value: "8", label: "8 Orang" },
  { value: "9", label: "9 Orang" },
];

const districtData = [
  {
    id: 3275031001,
    district_id: 327503,
    name: "Kaliabang Tengah",
  },
  {
    id: 3275031002,
    district_id: 327503,
    name: "Perwira",
  },
  {
    id: 3275031003,
    district_id: 327503,
    name: "Harapanbaru",
  },
  {
    id: 3275031004,
    district_id: 327503,
    name: "Teluk Pucung",
  },
  {
    id: 3275031005,
    district_id: 327503,
    name: "Margamulya",
  },
  {
    id: 3275031006,
    district_id: 327503,
    name: "Harapanjaya",
  },
];

export { optionFamilyMember, districtData };
